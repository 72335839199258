<template>
  <div>
    <van-nav-bar @click-left="onClickLeft" left-arrow>
      <template #title>
        <span style="font-weight: 600; color: #0af">我的简历</span>
      </template>
    </van-nav-bar>
    
<van-row>
  
  <van-cell label="完善简历，让高薪机会主动找到你" >
    <template #title> <van-icon name="label-o" /> 已填写{{ percentage }}% </template>
  </van-cell>

  <van-progress :percentage="percentage" />
</van-row>

<br>

    <van-cell-group inset>

      <van-cell :title="resumeBaseInfo.name == '' ? '基本信息' : resumeBaseInfo.name"
                size="large">
        <template #right-icon>
          <van-button type="info"
                      round
                      size="mini"
                      @click="baseEditShow = true">编辑</van-button>
        </template>
      </van-cell>

      <van-cell v-if="resumeBaseInfo.name === ''"
                center
                label="完善简历，让高薪机会主动找你。">
        <template #title> <van-icon name="completed" /> 完基本信息 </template>
      </van-cell>

      <van-cell v-else>
        <van-row >
          <van-col>
            <van-icon name="todo-list-o" /> {{ workedYear }}年工作经验</van-col>
          <van-col offset="1">
            <van-icon name="birthday-cake-o" /> {{ age }}岁</van-col>
          <van-col offset="1">
            <van-icon name="bulb-o" />
            {{ resumeBaseInfo.gender == 0 ? "男" : "女" }}</van-col>
        </van-row>

        <van-row >
          <van-col><van-icon name="phone-circle-o" /> {{ resumeBaseInfo.phone }}
          </van-col>
        </van-row>
        <van-row >
          <van-col><van-icon name="envelop-o" /> {{ resumeBaseInfo.email }}</van-col>
        </van-row>
      </van-cell>
    </van-cell-group>

    <br />
    <van-cell-group inset>
      <van-cell title="工作经历"
                size="large">
        <template #right-icon>
          <van-button type="info"
                      round
                      size="mini"
                      @click="addWorkExperienceShow = true">添加</van-button>
        </template>
      </van-cell>

      <van-cell v-if="workExperienceInfoList.length === 0"
                center
                label="让招聘方更好的了解你">
        <template #title> <van-icon name="hotel-o" /> 添加公司 </template>
      </van-cell>

      <van-cell v-for="item in workExperienceInfoList"
                :key="item.id">
        <van-row>
          <van-col span="22">
            <van-row>
              <van-col><van-icon name="hotel-o" /> {{ item.companyName }}
              </van-col>
            </van-row>
            <van-row>
              <van-icon name="underway-o" /> {{ item.workStartTime }} -
              {{ item.workEndTime === undefined ? "至今" : item.workEndTime }}
            </van-row>
            <van-row>
              <van-col><van-icon name="orders-o" /> {{ item.workDescription }}
              </van-col>
            </van-row>
          </van-col>
          <van-col span="2">
            <van-button type="info"
                        round
                        size="mini"
                        @click="editWorkExperienceOpen(item)">编辑</van-button></van-col>
        </van-row>
      </van-cell>
    </van-cell-group>
    <br />
    <van-cell-group inset>
      <van-cell title="教育经历"
                size="large">
        <template #right-icon>
          <van-button type="info"
                      round
                      size="mini"
                      @click="addEducationalExperienceShow = true">添加</van-button>
        </template>
      </van-cell>

      <van-cell v-if="educationalExperienceInfoList.length === 0"
                center
                label="让招聘方更好的了解你">
        <template #title> <van-icon name="shop-o" /> 添加毕业院校 </template>
      </van-cell>

      <van-cell v-for="item in educationalExperienceInfoList"
                :key="item.id">
        <van-row>
          <van-col span="22">
            <van-row>
              <van-col><van-icon name="apps-o" /> {{ item.schoolName }}
              </van-col>
            </van-row>
            <van-row>
              <van-col><van-icon name="bookmark-o" /> {{ item.major }} / {{ item.education }}
              </van-col>
            </van-row>
            <van-row>
              <van-icon name="underway-o" /> {{ item.startTime }} -
              {{ item.endTime === undefined ? "至今" : item.endTime }}
            </van-row>
            <van-row>
              <van-col><van-icon name="orders-o" /> {{ item.description }}
              </van-col>
            </van-row>
          </van-col>
          <van-col span="2">
            <van-button type="info"
                        round
                        size="mini"
                        @click="editEducationExperienceOpen(item)">编辑</van-button></van-col>
        </van-row>
      </van-cell>

    </van-cell-group>

    <br />
    <van-cell-group inset>
      <van-cell title="项目经历"
                size="large">
        <template #right-icon>
          <van-button type="info"
                      round
                      size="mini"
                      @click="addProjectShow = true">添加</van-button>
        </template>
      </van-cell>
      <van-cell v-if="projectInfoList.length === 0"
                center
                label="展示主要负责或有成果的项目">
        <template #title> <van-icon name="todo-list-o" /> 添加项目 </template>
      </van-cell>

      <van-cell v-for="item in projectInfoList"
                :key="item.id">
        <van-row>
          <van-col span="22">
            <van-row>
              <van-col><van-icon name="tv-o" /> {{ item.projectName }}
              </van-col>
            </van-row>
            <van-row>
              <van-col><van-icon name="manager-o" /> {{ item.role }}
              </van-col>
            </van-row>
            <van-row>
              <van-icon name="underway-o" /> {{ item.startTime }} -
              {{ item.endTime === undefined ? "至今" : item.endTime }}
            </van-row>
            <van-row>
              <van-col><van-icon name="orders-o" /> {{ item.description }}
              </van-col>
            </van-row>
          </van-col>
          <van-col span="2">
            <van-button type="info"
                        round
                        size="mini"
                        @click="editProjectOpen(item)">编辑</van-button></van-col>
        </van-row>
      </van-cell>

    </van-cell-group>
    <br />
    <van-cell-group inset>
      <van-cell title="求职期望"
                size="large">
        <template #right-icon>
          <van-button type="info"
                      round
                      size="mini"
                      @click="wishInfoEditShow = true">完善</van-button>
        </template>
      </van-cell>

      <van-cell v-if="wishInfo.position === ''"
                center
                label="更精准的为你推荐合适岗位">
        <template #title> <van-icon name="completed" /> 完善求职期望 </template>
      </van-cell>
      <van-cell v-else>
        <van-row>
          <van-col><van-icon name="manager-o" /> {{ wishInfo.position }}
          </van-col>
        </van-row>
        <van-row>
          <van-col><van-icon name="location-o" /> {{ wishInfo.city }}
          </van-col>
        </van-row>

        <van-row>
          <van-col><van-icon name="browsing-history-o" /> {{ wishInfo.industry }}
          </van-col>
        </van-row>
        <van-row>
          <van-col><van-icon name="balance-o" /> {{ wishInfo.salary }}
          </van-col>
        </van-row>
        <van-row>
          <van-col><van-icon name="flower-o" /> {{ wishInfo.status }}
          </van-col>
        </van-row>

      </van-cell>

    </van-cell-group>

    <!--我的简历编辑-->
    <van-popup round
               position="bottom"
               :style="{ height: '92%' }"
               v-model="baseEditShow">
      <van-nav-bar>
        <template #title>
          <span style="font-weight: 600; color: #0af">编辑基本信息</span>
        </template>
      </van-nav-bar>
      <br />

      <van-form @submit="saveResumeBaseInfo">
        <van-field v-model="resumeBaseInfo.name"
                   name="name"
                   required
                   label="真实姓名"
                   placeholder="真实姓名"
                   :rules="[{ required: true, message: '请填写真实姓名' }]" />

        <van-field name="gender"
                   required
                   label="性别">
          <template #input>
            <van-radio-group v-model="resumeBaseInfo.gender"
                             direction="horizontal">
              <van-radio :name="0">男</van-radio>
              <van-radio :name="1">女</van-radio>
            </van-radio-group>
          </template>
        </van-field>

        <van-field readonly
                   clickable
                   required
                   name="birthday"
                   :value="resumeBaseInfo.birthday"
                   label="生日"
                   placeholder="点击选择日期"
                   @click="showBirthdayPicker = true" />
        <van-popup v-model="showBirthdayPicker"
                   position="bottom">
          <van-datetime-picker title="生日"
                               type="date"
                               :min-date="minDate"
                               :max-date="maxDate"
                               @confirm="onBirthdayConfirm"
                               @cancel="showBirthdayPicker = false" />
        </van-popup>

        <van-field readonly
                   clickable
                   required
                   name="workYear"
                   :value="resumeBaseInfo.workYear"
                   label="参加工作时间"
                   placeholder="点击选择日期"
                   @click="showWorkStartDatePicker = true" />
        <van-popup v-model="showWorkStartDatePicker"
                   position="bottom">
          <van-datetime-picker title="参加工作时间"
                               type="date"
                               :min-date="minWorkDate"
                               :max-date="maxDate"
                               @confirm="onWorkStartDateConfirm"
                               @cancel="showWorkStartDatePicker = false" />
        </van-popup>
        <van-field name="phone"
                   required
                   v-model="resumeBaseInfo.phone"
                   label="手机号"
                   placeholder="请填写手机号"
                   :rules="[
            { pattern: /^1[3456789]\d{9}$/, message: '手机号格式不正确' },
          ]" />
        <van-field name="email"
                   required
                   v-model="resumeBaseInfo.email"
                   label="邮箱"
                   placeholder="请填写邮箱"
                   :rules="[
            {
              pattern: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
              message: '邮箱格式不正确',
            },
          ]" />
        <div style="margin: 16px">
          <van-button round
                      block
                      type="info"
                      native-type="submit">保存</van-button>
        </div>
      </van-form>
    </van-popup>

    <!--新增工作经历-->
    <van-popup round
               position="bottom"
               :style="{ height: '92%' }"
               v-model="addWorkExperienceShow">
      <van-nav-bar>
        <template #title>
          <span style="font-weight: 600; color: #0af">添加工作经历</span>
        </template>
      </van-nav-bar>
      <br />

      <van-form @submit="addWorkExperienceInfo">
        <van-field v-model="workExperienceInfo.companyName"
                   required
                   name="name"
                   label="公司名称"
                   placeholder="公司名称"
                   :rules="[{ required: true, message: '请填写公司名称' }]" />

        <van-field readonly
                   required
                   clickable
                   name="workStartTime"
                   :value="workExperienceInfo.workStartTime"
                   label="入职时间"
                   placeholder="选择日期"
                   @click="showWorkStartTimePicker = true"
                   :rules="[{ required: true, message: '请填写入职时间' }]" />
        <van-popup v-model="showWorkStartTimePicker"
                   position="bottom">
          <van-datetime-picker title="入职时间"
                               type="date"
                               :min-date="minWorkDate"
                               :max-date="maxDate"
                               @confirm="onWorkStartTimeConfirm"
                               @cancel="showWorkStartTimePicker = false" />
        </van-popup>
        <van-field readonly
                   clickable
                   name="workEndTime"
                   :value="workExperienceInfo.workEndTime"
                   label="离职时间"
                   placeholder="选择日期（在职可不填）"
                   @click="showWorkEndTimePicker = true" />
        <van-popup v-model="showWorkEndTimePicker"
                   position="bottom">
          <van-datetime-picker title="离职时间"
                               type="date"
                               :min-date="minWorkDate"
                               :max-date="maxDate"
                               @confirm="onWorkEndTimeConfirm"
                               @cancel="showWorkEndTimePicker = false" />
        </van-popup>

        <van-field v-model="workExperienceInfo.workDescription"
                   rows="2"
                   required
                   autosize
                   label="工作内容"
                   type="textarea"
                   maxlength="200"
                   placeholder="请描述【负责业务/取得成绩】建议30字以上，详细的描述更易展现工作能力"
                   show-word-limit
                   :rules="[{ required: true, message: '请填写工作内容' }]" />

        <div style="margin: 16px">
          <van-button round
                      block
                      type="info"
                      native-type="submit">保存</van-button>
        </div>
      </van-form>
    </van-popup>

    <!--编辑工作经历-->
    <van-popup round
               position="bottom"
               :style="{ height: '92%' }"
               v-model="editWorkExperienceShow">
      <van-nav-bar>
        <template #title>
          <span style="font-weight: 600; color: #0af">编辑工作经历</span>
        </template>
      </van-nav-bar>
      <br />

      <van-form>
        <van-field v-model="editWorkExperienceInfo.companyName"
                   required
                   name="name"
                   label="公司名称"
                   placeholder="公司名称"
                   :rules="[{ required: true, message: '请填写公司名称' }]" />

        <van-field readonly
                   clickable
                   required
                   name="workStartTime"
                   :value="editWorkExperienceInfo.workStartTime"
                   label="入职时间"
                   placeholder="选择日期"
                   @click="showWorkStartTimePicker = true"
                   :rules="[{ required: true, message: '请填写入职时间' }]" />
        <van-popup v-model="showWorkStartTimePicker"
                   position="bottom">
          <van-datetime-picker title="入职时间"
                               type="date"
                               :min-date="minWorkDate"
                               :max-date="maxDate"
                               @confirm="onEditWorkStartTimeConfirm"
                               @cancel="showWorkStartTimePicker = false" />
        </van-popup>
        <van-field readonly
                   clickable
                   name="workEndTime"
                   :value="editWorkExperienceInfo.workEndTime"
                   label="离职时间"
                   placeholder="选择日期（在职可不填）"
                   @click="showWorkEndTimePicker = true" />
        <van-popup v-model="showWorkEndTimePicker"
                   position="bottom">
          <van-datetime-picker title="离职时间"
                               type="date"
                               :min-date="minWorkDate"
                               :max-date="maxDate"
                               @confirm="onEditWorkEndTimeConfirm"
                               @cancel="showWorkEndTimePicker = false" />
        </van-popup>

        <van-field v-model="editWorkExperienceInfo.workDescription"
                   rows="2"
                   required
                   autosize
                   label="工作内容"
                   type="textarea"
                   maxlength="200"
                   placeholder="请描述【负责业务/取得成绩】建议30字以上，详细的描述更易展现工作能力"
                   show-word-limit
                   :rules="[{ required: true, message: '请填写工作内容' }]" />

        <div style="margin: 16px">
          <van-row gutter="20">
            <van-col :span="12">
              <van-button round
                          block
                          type="info"
                          @click="editWorkExperience()">保存</van-button>
            </van-col>
            <van-col :span="12">
              <van-button round
                          block
                          type="danger"
                          @click="deleteWorkExperience()">删除</van-button>
            </van-col>
          </van-row>
        </div>
      </van-form>
    </van-popup>

    <!--新增教育经历-->
    <van-popup round
               position="bottom"
               :style="{ height: '92%' }"
               v-model="addEducationalExperienceShow">
      <van-nav-bar>
        <template #title>
          <span style="font-weight: 600; color: #0af">添加教育经历</span>
        </template>
      </van-nav-bar>
      <br />

      <van-form @submit="addEducationalExperienceInfo">
        <van-field readonly
                   required
                   clickable
                   name="picker"
                   :value="educationalExperienceInfo.education"
                   label="学 历"
                   placeholder="选择学历"
                   @click="showEducationPicker = true"
                   :rules="[{ required: true, message: '请填写学历' }]" />
        <van-popup v-model="showEducationPicker"
                   position="bottom">
          <van-picker show-toolbar
                      :columns="educationColumns"
                      @confirm="onEducationConfirm"
                      @cancel="showEducationPicker = false" />
        </van-popup>

        <van-field v-model="educationalExperienceInfo.schoolName"
                   required
                   label="学校名称"
                   placeholder="请填写学校名称"
                   :rules="[{ required: true, message: '请填写学校名称' }]" />

        <van-field v-model="educationalExperienceInfo.major"
                   required
                   label="专业名称"
                   placeholder="请填写专业名称"
                   :rules="[{ required: true, message: '请填写专业名称' }]" />

        <van-field readonly
                   required
                   clickable
                   name="picker"
                   :value="educationalExperienceInfo.schoolLevel"
                   label="学校背景"
                   placeholder="选择学校背景"
                   @click="showSchoolLevelPicker = true"
                   :rules="[{ required: true, message: '请填写学校背景' }]" />
        <van-popup v-model="showSchoolLevelPicker"
                   position="bottom">
          <van-picker show-toolbar
                      :columns="schoolLevelColumns"
                      @confirm="onSchoolLevelConfirm"
                      @cancel="showSchoolLevelPicker = false" />
        </van-popup>

        <van-field readonly
                   required
                   clickable
                   name="startTime"
                   :value="educationalExperienceInfo.startTime"
                   label="入学时间"
                   placeholder="选择日期"
                   @click="showEducationalStartTimePicker = true"
                   :rules="[{ required: true, message: '请填写入学时间' }]" />
        <van-popup v-model="showEducationalStartTimePicker"
                   position="bottom">
          <van-datetime-picker title="入学时间"
                               type="date"
                               :min-date="minWorkDate"
                               :max-date="maxDate"
                               @confirm="onEducationStartTimeConfirm"
                               @cancel="showEducationalStartTimePicker = false" />
        </van-popup>
        <van-field readonly
                   clickable
                   required
                   name="workEndTime"
                   :value="educationalExperienceInfo.endTime"
                   label="毕业时间"
                   placeholder="选择日期"
                   @click="showEducationalEndTimePicker = true" />
        <van-popup v-model="showEducationalEndTimePicker"
                   position="bottom">
          <van-datetime-picker title="毕业时间"
                               type="date"
                               :min-date="minWorkDate"
                               :max-date="maxEndDate"
                               @confirm="onEducationEndTimeConfirm"
                               @cancel="showEducationalEndTimePicker = false"
                               :rules="[{ required: true, message: '请填写毕业时间' }]" />
        </van-popup>

        <van-field v-model="educationalExperienceInfo.description"
                   rows="2"
                   required
                   autosize
                   label="在校经历"
                   type="textarea"
                   maxlength="200"
                   placeholder="【获得荣誉】【专业成绩】等"
                   show-word-limit
                   :rules="[{ required: true, message: '请填写在校经历' }]" />

        <div style="margin: 16px">
          <van-button round
                      block
                      type="info"
                      native-type="submit">保存</van-button>
        </div>
      </van-form>
    </van-popup>

    <!--编辑教育经历-->
    <van-popup round
               position="bottom"
               :style="{ height: '92%' }"
               v-model="editEducationalExperienceShow">
      <van-nav-bar>
        <template #title>
          <span style="font-weight: 600; color: #0af">添加教育经历</span>
        </template>
      </van-nav-bar>
      <br />

      <van-form>
        <van-field readonly
                   required
                   clickable
                   name="picker"
                   :value="editEducationalExperienceInfo.education"
                   label="学 历"
                   placeholder="选择学历"
                   @click="showEditEducationPicker = true"
                   :rules="[{ required: true, message: '请填写学历' }]" />
        <van-popup v-model="showEditEducationPicker"
                   position="bottom">
          <van-picker show-toolbar
                      :columns="educationColumns"
                      @confirm="onEditEducationConfirm"
                      @cancel="showEditEducationPicker = false" />
        </van-popup>

        <van-field v-model="editEducationalExperienceInfo.schoolName"
                   required
                   label="学校名称"
                   placeholder="请填写学校名称"
                   :rules="[{ required: true, message: '请填写学校名称' }]" />

        <van-field v-model="editEducationalExperienceInfo.major"
                   required
                   label="专业名称"
                   placeholder="请填写专业名称"
                   :rules="[{ required: true, message: '请填写专业名称' }]" />

        <van-field readonly
                   required
                   clickable
                   name="picker"
                   :value="editEducationalExperienceInfo.schoolLevel"
                   label="学校背景"
                   placeholder="选择学校背景"
                   @click="showEditSchoolLevelPicker = true"
                   :rules="[{ required: true, message: '请填写学校背景' }]" />
        <van-popup v-model="showEditSchoolLevelPicker"
                   position="bottom">
          <van-picker show-toolbar
                      :columns="schoolLevelColumns"
                      @confirm="onEditSchoolLevelConfirm"
                      @cancel="showEditSchoolLevelPicker = false" />
        </van-popup>

        <van-field readonly
                   required
                   clickable
                   name="startTime"
                   :value="editEducationalExperienceInfo.startTime"
                   label="入学时间"
                   placeholder="选择日期"
                   @click="showEducationalStartTimePicker = true"
                   :rules="[{ required: true, message: '请填写入学时间' }]" />
        <van-popup v-model="showEducationalStartTimePicker"
                   position="bottom">
          <van-datetime-picker title="入学时间"
                               type="date"
                               :min-date="minWorkDate"
                               :max-date="maxDate"
                               @confirm="onEditEducationStartTimeConfirm"
                               @cancel="showEducationalStartTimePicker = false" />
        </van-popup>
        <van-field readonly
                   clickable
                   required
                   name="workEndTime"
                   :value="editEducationalExperienceInfo.endTime"
                   label="毕业时间"
                   placeholder="选择日期"
                   @click="showEducationalEndTimePicker = true" />
        <van-popup v-model="showEducationalEndTimePicker"
                   position="bottom">
          <van-datetime-picker title="毕业时间"
                               type="date"
                               :min-date="minWorkDate"
                               :max-date="maxEndDate"
                               @confirm="onEditEducationEndTimeConfirm"
                               @cancel="showEducationalEndTimePicker = false"
                               :rules="[{ required: true, message: '请填写毕业时间' }]" />
        </van-popup>

        <van-field v-model="editEducationalExperienceInfo.description"
                   rows="2"
                   required
                   autosize
                   label="在校经历"
                   type="textarea"
                   maxlength="200"
                   placeholder="【获得荣誉】【专业成绩】等"
                   show-word-limit
                   :rules="[{ required: true, message: '请填写在校经历' }]" />

        <div style="margin: 16px">
          <van-row gutter="20">
            <van-col :span="12">
              <van-button round
                          block
                          type="info"
                          @click="editEducationExperience()">保存</van-button>
            </van-col>
            <van-col :span="12">
              <van-button round
                          block
                          type="danger"
                          @click="deleteEducationExperience()">删除</van-button>
            </van-col>
          </van-row>
        </div>
      </van-form>
    </van-popup>

    <!--新增项目经历-->
    <van-popup round
               position="bottom"
               :style="{ height: '92%' }"
               v-model="addProjectShow">
      <van-nav-bar>
        <template #title>
          <span style="font-weight: 600; color: #0af">添加教育经历</span>
        </template>
      </van-nav-bar>
      <br />

      <van-form @submit="addProjectInfo">

        <van-field v-model="projectInfo.projectName"
                   required
                   label="项目名称"
                   placeholder="请填写项目名称"
                   :rules="[{ required: true, message: '请填写项目名称' }]" />

        <van-field v-model="projectInfo.role"
                   required
                   label="担任角色"
                   placeholder="请填写担任角色"
                   :rules="[{ required: true, message: '请填写担任角色' }]" />

        <van-field readonly
                   required
                   clickable
                   name="projectStartTime"
                   :value="projectInfo.startTime"
                   label="开始时间"
                   placeholder="选择日期"
                   @click="showProjectStartTimePicker = true"
                   :rules="[{ required: true, message: '请填写开始时间' }]" />
        <van-popup v-model="showProjectStartTimePicker"
                   position="bottom">
          <van-datetime-picker title="开始时间"
                               type="date"
                               :min-date="minWorkDate"
                               :max-date="maxDate"
                               @confirm="onProjectStartTimeConfirm"
                               @cancel="showProjectStartTimePicker = false" />
        </van-popup>
        <van-field readonly
                   clickable
                   required
                   name="projectEndTime"
                   :value="projectInfo.endTime"
                   label="结束时间"
                   placeholder="选择日期"
                   @click="showProjectEndTimePicker = true" />
        <van-popup v-model="showProjectEndTimePicker"
                   position="bottom">
          <van-datetime-picker title="结束时间"
                               type="date"
                               :min-date="minWorkDate"
                               :max-date="maxEndDate"
                               @confirm="onProjectEndTimeConfirm"
                               @cancel="showProjectEndTimePicker = false"
                               :rules="[{ required: true, message: '请填写结束时间' }]" />
        </van-popup>

        <van-field v-model="projectInfo.description"
                   rows="2"
                   required
                   autosize
                   label="项目描述"
                   type="textarea"
                   maxlength="200"
                   placeholder="描述一下你的项目及相关亮点。"
                   show-word-limit
                   :rules="[{ required: true, message: '请填写项目描述' }]" />

        <div style="margin: 16px">
          <van-button round
                      block
                      type="info"
                      native-type="submit">保存</van-button>
        </div>
      </van-form>
    </van-popup>

    <!--编辑项目经历-->
    <van-popup round
               position="bottom"
               :style="{ height: '92%' }"
               v-model="editProjectShow">
      <van-nav-bar>
        <template #title>
          <span style="font-weight: 600; color: #0af">编辑教育经历</span>
        </template>
      </van-nav-bar>
      <br />

      <van-form>

        <van-field v-model="editProjectInfo.projectName"
                   required
                   label="项目名称"
                   placeholder="请填写项目名称"
                   :rules="[{ required: true, message: '请填写项目名称' }]" />

        <van-field v-model="editProjectInfo.role"
                   required
                   label="担任角色"
                   placeholder="请填写担任角色"
                   :rules="[{ required: true, message: '请填写担任角色' }]" />

        <van-field readonly
                   required
                   clickable
                   name="projectStartTime"
                   :value="editProjectInfo.startTime"
                   label="开始时间"
                   placeholder="选择日期"
                   @click="showProjectStartTimePicker = true"
                   :rules="[{ required: true, message: '请填写开始时间' }]" />
        <van-popup v-model="showProjectStartTimePicker"
                   position="bottom">
          <van-datetime-picker title="开始时间"
                               type="date"
                               :min-date="minWorkDate"
                               :max-date="maxDate"
                               @confirm="onEditProjectStartTimeConfirm"
                               @cancel="showProjectStartTimePicker = false" />
        </van-popup>
        <van-field readonly
                   clickable
                   required
                   name="projectEndTime"
                   :value="editProjectInfo.endTime"
                   label="结束时间"
                   placeholder="选择日期"
                   @click="showProjectEndTimePicker = true" />
        <van-popup v-model="showProjectEndTimePicker"
                   position="bottom">
          <van-datetime-picker title="结束时间"
                               type="date"
                               :min-date="minWorkDate"
                               :max-date="maxEndDate"
                               @confirm="onEditProjectEndTimeConfirm"
                               @cancel="showProjectEndTimePicker = false"
                               :rules="[{ required: true, message: '请填写结束时间' }]" />
        </van-popup>

        <van-field v-model="editProjectInfo.description"
                   rows="2"
                   required
                   autosize
                   label="项目描述"
                   type="textarea"
                   maxlength="200"
                   placeholder="描述一下你的项目及相关亮点。"
                   show-word-limit
                   :rules="[{ required: true, message: '请填写项目描述' }]" />

        <div style="margin: 16px">
          <van-row gutter="20">
            <van-col :span="12">
              <van-button round
                          block
                          type="info"
                          @click="editProject()">保存</van-button>
            </van-col>
            <van-col :span="12">
              <van-button round
                          block
                          type="danger"
                          @click="deleteProject()">删除</van-button>
            </van-col>
          </van-row>
        </div>
      </van-form>
    </van-popup>

    <!--求职期望编辑-->
    <van-popup round
               position="bottom"
               :style="{ height: '92%' }"
               v-model="wishInfoEditShow">
      <van-nav-bar>
        <template #title>
          <span style="font-weight: 600; color: #0af">我的求职偏好</span>
        </template>
      </van-nav-bar>
      <br />
      <van-form @submit="saveWishInfo">
        <van-field v-model="wishInfo.position"
                   required
                   name="position"
                   label="期望职位"
                   placeholder="期望职位"
                   :rules="[{ required: true, message: '请填写期望职位' }]" />
        <van-field v-model="wishInfo.city"
                   name="city"
                   required
                   label="期望城市"
                   placeholder="期望城市"
                   :rules="[{ required: true, message: '请填写期望城市' }]" />

        <van-field v-model="wishInfo.industry"
                   name="industry"
                   label="期望行业"
                   placeholder="期望行业" />

        <van-field v-model="wishInfo.salary"
                   name="salary"
                   label="期望月薪"
                   placeholder="期望月薪" />

        <van-field name="radio"
                   label="求职状态">
          <template #input>
            <van-radio-group v-model="wishInfo.status"
                             direction="horizontal">
              <van-radio name="正在看机会">正在看机会</van-radio>
              <van-radio name="关注行情">关注行情</van-radio>
              <van-radio name="半年不看机会">半年不看机会</van-radio>
            </van-radio-group>
          </template>
        </van-field>

        <div style="margin: 16px">
          <van-button round
                      block
                      type="info"
                      native-type="submit">保存</van-button>
        </div>
      </van-form>
    </van-popup>

  </div>
</template>

<script>
import {
  Progress, 
  Picker,
  DatetimePicker,
  RadioGroup,
  Radio,
  Form,
  Field,
  Empty,
  Toast,
  Popup,
  NavBar,
  Button,
  Row,
  Col,
  Icon,
  Cell,
  CellGroup,
} from "vant";
const axios = require("axios");
export default {
  components: {
    [Progress.name]:Progress,
    [Picker.name]: Picker,
    [DatetimePicker.name]: DatetimePicker,
    [RadioGroup.name]: RadioGroup,
    [Radio.name]: Radio,
    [Form.name]: Form,
    [Field.name]: Field,
    [Empty.name]: Empty,
    [Toast.name]: Toast,
    // ResumeBase,
    [Popup.name]: Popup,
    [NavBar.name]: NavBar,
    [Button.name]: Button,
    [Row.name]: Row,
    [Col.name]: Col,
    [Icon.name]: Icon,
    [Cell.name]: Cell,
    [CellGroup.name]: CellGroup,
  },
  data () {
    return {
      //简历完善百分比
      percentage: 0,
      //基本信息
      resumeBaseInfo: {
        name: "",
        birthday: "",
        email: "",
        gender: "0",
        phone: "",
        workYear: "",
      },
      baseEditShow: false,
      minDate: new Date(1980, 0, 1),
      minWorkDate: new Date(2000, 0, 1),
      maxDate: new Date(),
      maxEndDate: new Date(2080, 0, 1),
      showBirthdayPicker: false,
      showWorkStartDatePicker: false,
      //新增工作经历
      addWorkExperienceShow: false,
      workExperienceInfo: {
        companyName: "",
        workStartTime: "",
        workEndTime: "",
        workDescription: "",
      },
      showWorkStartTimePicker: false,
      showWorkEndTimePicker: false,
      workExperienceInfoList: "",
      //编辑工作经历
      editWorkExperienceShow: false,
      editWorkExperienceInfo: {
        companyName: "腾讯",
        createdTime: "2023-10-29",
        id: 1,
        updatedTime: "2023-10-29",
        userId: 299,
        workDescription: "负责微信开发",
        workEndTime: "2006-01-01",
        workStartTime: "2000-01-01",
      },
      //新增教育经历
      addEducationalExperienceShow: false,
      educationalExperienceInfo: {
        education: "",
        schoolName: "",
        major: "",
        schoolLevel: "",
        startTime: "",
        endTime: "",
        description: "",
      },
      educationColumns: ["专科", "本科", "硕士", "博士"],
      showEducationPicker: false,
      schoolLevelColumns: ["双非", "211", "985", "C9", "其他"],
      showSchoolLevelPicker: false,
      showEducationalStartTimePicker: false,
      showEducationalEndTimePicker: false,
      educationalExperienceInfoList: "",
      //编辑教育经历 
      editEducationalExperienceShow: false,
      showEditEducationPicker: false,
      showEditSchoolLevelPicker: false,
      editEducationalExperienceInfo: {
        education: "",
        schoolName: "",
        major: "",
        schoolLevel: "",
        startTime: "",
        endTime: "",
        description: ""
      },
      //新增项目经历
      addProjectShow: false,
      projectInfo: {
        projectName: "",
        role: "",
        startTime: "",
        endTime: "",
        description: "",
      },
      showProjectPicker: false,
      showProjectStartTimePicker: false,
      showProjectEndTimePicker: false,
      projectInfoList: "",
      //编辑项目经历
      editProjectShow: false,
      editProjectInfo: {
        projectName: "",
        role: "",
        startTime: "",
        endTime: "",
        description: "",
      },
      //完善求职期望
      wishInfo: {
        position: "",
        city: "",
        industry: "",
        salary: "",
        status: ""
      },
      wishInfoEditShow: false,
    };
  },
  created () {
    this.getResumeInfo();
  },
  computed: {
    age () {
      if (this.resumeBaseInfo.birthday != "") {
        const birthDate = new Date(this.resumeBaseInfo.birthday);
        const timeDiff = Date.now() - birthDate.getTime();
        const age = Math.floor(timeDiff / (1000 * 60 * 60 * 24 * 365));
        return age;
      } else {
        return 0;
      }
    },
    workedYear () {
      if (this.resumeBaseInfo.workYear != "") {
        const workYearDate = new Date(this.resumeBaseInfo.workYear);
        const timeDiff = Date.now() - workYearDate.getTime();
        const workedYear = Math.floor(timeDiff / (1000 * 60 * 60 * 24 * 365));
        return workedYear;
      } else {
        return 0;
      }
    },
  },
  methods: {
    onClickLeft() {
      this.$router.push("user");
    },
    getResumeInfo () {
      axios
        .post("https://api.golangroadmap.com/resume/info")
        .then((response) => {
          if (response.status == "200" && response.data.code == "100") {

            if (response.data.data.resumeBaseInfo !== undefined) {
              this.resumeBaseInfo = response.data.data.resumeBaseInfo;
            }
            this.workExperienceInfoList = response.data.data.workExperienceInfoList;
            this.educationalExperienceInfoList = response.data.data.educationalExperienceInfoList;
            this.projectInfoList = response.data.data.projectInfoList;
            if (response.data.data.wishInfo !== undefined) {
              this.wishInfo = response.data.data.wishInfo;
            }
            this.percentage = response.data.data.percentage;

          } else {
            Toast.fail(response.data.msg);
          }
        })
        .catch((error) => {
          Toast.fail(error);
        });
    },
    saveResumeBaseInfo () {
      axios
        .post(
          "https://api.golangroadmap.com/resume/modifyBaseInfo",
          this.resumeBaseInfo
        )
        .then((response) => {
          if (response.status == "200" && response.data.code == "100") {
            Toast.success("基本信息保存成功!");
            this.baseEditShow = false;
          } else {
            Toast.fail(response.data.msg);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    onBirthdayConfirm (date) {
      this.resumeBaseInfo.birthday = this.formatDate(date);
      this.showBirthdayPicker = false;
    },
    onWorkStartDateConfirm (date) {
      this.resumeBaseInfo.workYear = this.formatDate(date);
      this.showWorkStartDatePicker = false;
    },
    onWorkStartTimeConfirm (date) {
      this.workExperienceInfo.workStartTime = this.formatDate(date);
      this.showWorkStartTimePicker = false;
    },
    onWorkEndTimeConfirm (date) {
      this.workExperienceInfo.workEndTime = this.formatDate(date);
      this.showWorkEndTimePicker = false;
    },
    onEditWorkStartTimeConfirm (date) {
      this.editWorkExperienceInfo.workStartTime = this.formatDate(date);
      this.showWorkStartTimePicker = false;
    },
    onEditWorkEndTimeConfirm (date) {
      this.editWorkExperienceInfo.workEndTime = this.formatDate(date);
      this.showWorkEndTimePicker = false;
    },
    onEducationConfirm (value) {
      this.educationalExperienceInfo.education = value;
      this.showEducationPicker = false;
    },
    onEditEducationConfirm (value) {
      this.editEducationalExperienceInfo.education = value;
      this.showEditEducationPicker = false;
    },
    onSchoolLevelConfirm (value) {
      this.educationalExperienceInfo.schoolLevel = value;
      this.showSchoolLevelPicker = false;
    },
    onEditSchoolLevelConfirm (value) {
      this.editEducationalExperienceInfo.schoolLevel = value;
      this.showEditSchoolLevelPicker = false;
    },
    onEducationStartTimeConfirm (date) {
      this.educationalExperienceInfo.startTime = this.formatDate(date);
      this.showEducationalStartTimePicker = false;
    },
    onEducationEndTimeConfirm (date) {
      this.educationalExperienceInfo.endTime = this.formatDate(date);
      this.showEducationalEndTimePicker = false;
    },
    onEditEducationStartTimeConfirm (date) {
      this.editEducationalExperienceInfo.startTime = this.formatDate(date);
      this.showEducationalStartTimePicker = false;
    },
    onEditEducationEndTimeConfirm (date) {
      this.editEducationalExperienceInfo.endTime = this.formatDate(date);
      this.showEducationalEndTimePicker = false;
    },
    formatDate (date) {
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, "0");
      const day = String(date.getDate()).padStart(2, "0");

      return `${year}-${month}-${day}`;
    },
    // formatDate2(date) {
    //   const year = date.getFullYear();
    //   const month = String(date.getMonth() + 1).padStart(2, "0");
    //   return `${year}-${month}-01`;
    // },
    resetWorkExperienceInfo () {
      this.workExperienceInfo.companyName = "";
      this.workExperienceInfo.workStartTime = "";
      this.workExperienceInfo.workEndTime = "";
      this.workExperienceInfo.workDescription = "";
    },
    addWorkExperienceInfo () {
      console.log("add work");
      axios
        .post(
          "https://api.golangroadmap.com/resume/addWorkExperienceInfo",
          this.workExperienceInfo
        )
        .then((response) => {
          if (response.status == "200" && response.data.code == "100") {
            Toast.success("新增工作经历成功!");
            this.addWorkExperienceShow = false;
            this.workExperienceInfoList = response.data.data;
            this.resetWorkExperienceInfo();
          } else {
            Toast.fail(response.data.msg);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    editWorkExperienceOpen (item) {
      this.editWorkExperienceInfo = item;
      this.editWorkExperienceShow = true;
    },
    editWorkExperience () {
      axios
        .post(
          "https://api.golangroadmap.com/resume/editWorkExperienceInfo",
          this.editWorkExperienceInfo
        )
        .then((response) => {
          if (response.status == "200" && response.data.code == "100") {
            Toast.success("编辑工作经历成功!");
            this.editWorkExperienceShow = false;
            this.workExperienceInfoList = response.data.data;
          } else {
            Toast.fail(response.data.msg);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    deleteWorkExperience () {
      axios
        .post(
          "https://api.golangroadmap.com/resume/deleteWorkExperienceInfo",
          this.editWorkExperienceInfo
        )
        .then((response) => {
          if (response.status == "200" && response.data.code == "100") {
            Toast.success("删除工作经历成功!");
            this.editWorkExperienceShow = false;
            this.workExperienceInfoList = response.data.data;
          } else {
            Toast.fail(response.data.msg);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    resetEducationlExperienceInfo () {

      this.educationalExperienceInfo.education = "";
      this.educationalExperienceInfo.schoolName = "";
      this.educationalExperienceInfo.major = "";
      this.educationalExperienceInfo.schoolLevel = "";
      this.educationalExperienceInfo.startTime = "";
      this.educationalExperienceInfo.endTime = "";
      this.educationalExperienceInfo.description = "";

    },
    addEducationalExperienceInfo () {
      // console.log("addEducationalExperienceInfo ");
      console.log(this.educationalExperienceInfo);
      axios
        .post(
          "https://api.golangroadmap.com/resume/addEducationalExperienceInfo",
          this.educationalExperienceInfo
        )
        .then((response) => {
          if (response.status == "200" && response.data.code == "100") {
            Toast.success("新增教育经历成功!");
            this.addEducationalExperienceShow = false;
            this.educationalExperienceInfoList = response.data.data;
            this.resetEducationlExperienceInfo();
          } else {
            Toast.fail(response.data.msg);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    editEducationExperienceOpen (item) {
      this.editEducationalExperienceInfo = item;
      this.editEducationalExperienceShow = true;
    },
    editEducationExperience () {
      axios
        .post(
          "https://api.golangroadmap.com/resume/editEducationalExperienceInfo",
          this.editEducationalExperienceInfo
        )
        .then((response) => {
          if (response.status == "200" && response.data.code == "100") {
            Toast.success("编辑教育经历成功!");
            this.editEducationalExperienceShow = false;
            this.educationalExperienceInfoList = response.data.data;
          } else {
            Toast.fail(response.data.msg);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    deleteEducationExperience () {
      axios
        .post(
          "https://api.golangroadmap.com/resume/deleteEducationalExperienceInfo",
          this.editEducationalExperienceInfo
        )
        .then((response) => {
          if (response.status == "200" && response.data.code == "100") {
            Toast.success("删除教育经历成功!");
            this.editEducationalExperienceShow = false;
            this.educationalExperienceInfoList = response.data.data;
          } else {
            Toast.fail(response.data.msg);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    // 项目经历
    onProjectStartTimeConfirm (date) {
      this.projectInfo.startTime = this.formatDate(date);
      this.showProjectStartTimePicker = false;
    },
    onProjectEndTimeConfirm (date) {
      this.projectInfo.endTime = this.formatDate(date);
      this.showProjectEndTimePicker = false;
    },
    onEditProjectStartTimeConfirm (date) {
      this.editProjectInfo.startTime = this.formatDate(date);
      this.showProjectStartTimePicker = false;
    },
    onEditProjectEndTimeConfirm (date) {
      this.editProjectInfo.endTime = this.formatDate(date);
      this.showProjectEndTimePicker = false;
    },
    resetProjectInfo () {
      this.projectInfo.projectName = "";
      this.projectInfo.role = "";
      this.projectInfo.startTime = "";
      this.projectInfo.endTime = "";
      this.projectInfo.description = "";
    },
    addProjectInfo () {
      console.log("add project");
      axios
        .post(
          "https://api.golangroadmap.com/resume/addProjectInfo",
          this.projectInfo
        )
        .then((response) => {
          if (response.status == "200" && response.data.code == "100") {
            Toast.success("新增工作经历成功!");
            this.addProjectShow = false;
            this.projectInfoList = response.data.data;
            this.resetProjectInfo();
          } else {
            Toast.fail(response.data.msg);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    editProjectOpen (item) {
      this.editProjectInfo = item;
      this.editProjectShow = true;
    },
    editProject () {
      axios
        .post(
          "https://api.golangroadmap.com/resume/editProjectInfo",
          this.editProjectInfo
        )
        .then((response) => {
          if (response.status == "200" && response.data.code == "100") {
            Toast.success("编辑项目经历成功!");
            this.editProjectShow = false;
            this.projectInfoList = response.data.data;
          } else {
            Toast.fail(response.data.msg);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    deleteProject () {
      axios
        .post(
          "https://api.golangroadmap.com/resume/deleteProjectInfo",
          this.editProjectInfo
        )
        .then((response) => {
          if (response.status == "200" && response.data.code == "100") {
            Toast.success("删除项目经历成功!");
            this.editProjectShow = false;
            this.projectInfoList = response.data.data;
          } else {
            Toast.fail(response.data.msg);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    saveWishInfo () {
      axios
        .post(
          "https://api.golangroadmap.com/resume/modifyWishInfo",
          this.wishInfo
        )
        .then((response) => {
          if (response.status == "200" && response.data.code == "100") {
            Toast.success("求职期望保存成功!");
            this.wishInfoEditShow = false;
          } else {
            Toast.fail(response.data.msg);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  },
};
</script>

<style lang="less">
// .resume-item {
//   padding: 5px 16px;
//   font-size: 14px;
// }

.user {
  &-poster {
    width: 100%;
    height: 53vw;
    display: block;
  }

  &-group {
    margin-bottom: 15px;
  }

  &-links {
    padding: 15px 0;
    font-size: 12px;
    text-align: center;
    background-color: #fff;

    .van-icon {
      display: block;
      font-size: 24px;
    }
  }
}
</style>
